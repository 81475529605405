import { SocialApiTypes } from '@wix/social-groups-api/dist/src/types';
import { SocialText } from './feed';
import { ITopic } from './ITopic';

export interface IFeedItemPin {
  since?: Date;
  pinnedBy?: string;
}

export interface IFeedItemRequester {
  subscribed?: boolean;
}

export interface IAttachment extends SocialApiTypes.Resource {}

export interface IComment {
  commentId: string;
  createdAt: Date;
  createdBy: SocialApiTypes.SocialIdentity;
  comment: ICommentEntity;
}

export interface IReaction {
  reactionCode: string;
}

export interface IReactions {
  total: number;
  usersReactions: IUsersReactions;
  usersReacted: IUsersReacted;
}

export interface IUsersReacted {
  total: number;
  userIds: string[];
}

export interface IUserReaction {
  userId: string;
  reaction: IReaction;
  reactionTime: Date;
}

export interface IUsersReactions {
  total: number;
  reactions: IUserReaction[];
}

export interface ICommentEntity {
  text: SocialText;
}

export interface IFeedItemEntity {
  body: SocialText;
  topics: string[];
}

export interface IFeedItemComments {
  total: number;
  contextToken: string;
}

export interface IFeedItemActivity {
  activityType: FeedItemActivityType;
  data: IFeedItemActivityData;
}

export enum FeedItemActivityType {
  DRAFT_JS = 'DRAFTJS',
  GROUP_COVER_CHANGED = 'GROUP_COVER_CHANGED',
  ABOUT_GROUP_CHANGED = 'ABOUT_GROUP_CHANGED',
  USERS_JOINED = 'USERS_JOINED',
}

export interface IFeedItemActivityData
  extends IGroupCoverChangedActivity,
    IAboutGroupChangedActivity,
    IUsersJoinedActivity,
    IDraftJSActivity {}

export interface IGroupCoverChangedActivity {
  hasCoverBefore: boolean;
  src: string;
}

export interface IAboutGroupChangedActivity {
  content: string;
}

export interface IUsersJoinedActivity {
  authorUserId: string;
  userIds: string[];
}

export interface IDraftJSActivity {
  content: string;
  activity: string;
}

export interface IFeedItem {
  feedItemId: string;
  createdAt: Date;
  createdBy: string;
  activity?: IFeedItemActivity;
  entity?: IFeedItemEntity;
  comments: IFeedItemComments;
  reactions: IReactions;
  attachments?: IAttachment[];
  pin?: IFeedItemPin;
  requesterContext?: IFeedItemRequester;
  updatedAt?: Date;
}

export interface IFeedStore {
  loading: boolean;
  feedItems: IFeedItem[];
  topics: ITopic[];
  cursor: string | null;
  prevCursor: string | null;
}
