import React, { FC, useContext, useEffect, useMemo, useState } from 'react';

import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import { useTranslation, useExperiments } from '@wix/yoshi-flow-editor';
import * as eventHelpers from '@wix/social-groups-api/dist/src/model/Events/helpers';

import { Box } from '../../../../../common/components/Box/Box';
import { EventsContext } from '../../../contexts/events/EventsContext';
import { GroupActionsContext } from '../../../contexts/GroupActions/GroupActionsContext';
import { GroupContext } from '../../../contexts/Group/GroupContext';
import { Tab } from '../../../../../common/controllers';

import * as discussionStyles from '../../Discussion/Discussion.st.css';
import { EventsErrorBoundary } from '../EventsErrorBoundary';
import { filterEvents, sortPast } from '../helpers';

import { classes } from './EventsWidget.st.css';

const _EventsWidget: FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { events, fetch } = useContext(EventsContext);
  const { experiments } = useExperiments();
  const { apps } = useContext(GroupContext);
  const { changeTab } = useContext(GroupActionsContext);

  const enabled =
    apps.EVENTS_APP.installed &&
    experiments.enabled('specs.groups.EventsIntegration');

  useEffect(() => {
    if (!enabled) {
      return;
    }

    void fetchEvents();

    async function fetchEvents() {
      setLoading(true);
      await fetch();
      setLoading(false);
    }
  }, [enabled]);

  const eventsToShow = useMemo(() => {
    if (!events?.length) {
      return [];
    }

    const [upcoming, past] = filterEvents(events);
    const toShow = upcoming || sortPast(past);

    return toShow.slice(0, 2);
  }, [events]);

  if (loading) {
    return null;
  }

  if (!enabled || !events?.length) {
    return null;
  }

  return (
    <Box article>
      <Text className={discussionStyles.classes.widgetHeader}>
        {t('groups-web.discussion.events.widget.title')}
      </Text>
      <ul>
        {eventsToShow.map((event) => {
          const tbd = eventHelpers.getScheduleTbdMessage(event);
          const [day, weekday, month] =
            eventHelpers.getStartDate(event, 'DD/ddd/MMM')?.split('/') || [];

          const date = tbd || `${day} ${month}, ${weekday}`;

          return (
            <li key={event.id}>
              <Text
                tagName="p"
                className={classes.eventTitle}
                typography={TYPOGRAPHY.listText}
              >
                {`${date} | '${event.title}'`}
              </Text>
            </li>
          );
        })}
      </ul>
      <TextButton
        priority={TEXT_BUTTON_PRIORITY.primary}
        onClick={navigateToEventsTab}
      >
        {t('groups-web.discussion.events.widget.view-all')}
      </TextButton>
    </Box>
  );

  function navigateToEventsTab() {
    changeTab(Tab.EVENTS);
  }
};

export const EventsWidget = EventsErrorBoundary(_EventsWidget);
