// TODO: [YO] fix types
export function usePolls(props: any) {
  const { handleUserValidationRequest } = props;

  return {
    validateUser: handleUserValidationRequest ? validateUser : null,
  };

  function validateUser() {
    return new Promise(handleUserValidationRequest);
  }
}
