import { AmbassadorHTTPError } from '@wix/ambassador/runtime/http';
import { ApplicationError } from '../../../../common/api/errors/ApplicationError';

export enum ErrorOrigin {
  Group = 'Group',
  Feed = 'Feed',
  JoinGroup = 'JoinGroup',
}
export enum ApiErrorCode {
  ACCESS_RESTRICTION_NOT_SATISFIED = 'ACCESS_RESTRICTION_NOT_SATISFIED',
}

export interface IErrorEvent {
  code: number;
  origin: ErrorOrigin;
  apiError?: ApiErrorCode;
}

export function errorEventFromAmbassador(
  e: AmbassadorHTTPError,
  origin: ErrorOrigin,
): IErrorEvent {
  const { response, httpStatus } = e;
  const error = new ApplicationError(response);
  return {
    origin,
    apiError: error.getCode(),
    code: httpStatus,
  };
}
