import React from 'react';

import {
  canJoinGroup,
  shouldSubmitJoinRequest,
  isJoined,
} from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { PRIORITY } from 'wix-ui-tpa/Button';

import { DATA_HOOKS } from './dataHooks';

import { classes } from './MembershipButton.st.css';
import { WithGroup, WithGroupProps } from '../../contexts/Group/WithGroup';
import {
  InjectedEnvironmentProps,
  withEnvironment,
  withTranslation,
  WithTranslation,
} from '@wix/yoshi-flow-editor';
import {
  withProfile,
  WithProfileProps,
} from '../../contexts/Profile/withProfile';
import { MembershipChangeAction } from '../../types/MembershipAction';
import {
  withAppData,
  WithAppDataProps,
} from '../../contexts/AppData/withAppData';
import { UpdateProgress } from '../../../../common/ContentEditor/UpdateProgress';
import { Button } from '../../../../common/components/Button';
import { Button as ButtonType } from '../../types/button';
import { compose } from '../../../../common/utils/compose';
import { withMembershipChangeAction } from '../../contexts/Membership/withMembershipChangeAction';
import classname from 'classnames';

interface MembershipButtonConfig {
  buttonLabelKey: string;
  priority: PRIORITY;

  onClick?(): void;
}

const getMembershipButtonPreferences = (
  group: ApiTypes.v1.GroupResponse,
): MembershipButtonConfig => {
  const joinLabel = shouldSubmitJoinRequest(group)
    ? 'groups-web.request-to-join'
    : 'groups-web.join';

  const config: {
    [key in ApiTypes.v1.RelationshipWithGroup]: MembershipButtonConfig;
  } = {
    [ApiTypes.v1.RelationshipWithGroup.NONE]: {
      buttonLabelKey: joinLabel,
      priority: PRIORITY.primary,
    },
    [ApiTypes.v1.RelationshipWithGroup.PENDING_APPROVAL]: {
      buttonLabelKey: 'groups-web.pending',
      priority: PRIORITY.secondary,
    },
    [ApiTypes.v1.RelationshipWithGroup.JOINED]: {
      buttonLabelKey: 'groups-web.joined',
      priority: PRIORITY.primary,
    },
    [ApiTypes.v1.RelationshipWithGroup.REJECTED_MEMBERSHIP]: {
      buttonLabelKey: joinLabel,
      priority: PRIORITY.primary,
    },
  };
  return config[group.relationship!];
};

export interface MembershipButtonProps {
  className?: string;
  biOrigin?: string;
}

type Props = WithGroupProps &
  WithTranslation &
  WithProfileProps &
  MembershipChangeAction &
  MembershipButtonProps &
  WithAppDataProps &
  InjectedEnvironmentProps;

interface State {
  shouldChangeMembership: boolean;
}

class MembershipButtonComponent extends React.Component<Props, State> {
  state: State = {
    shouldChangeMembership: false,
  };
  private privacyChanged!: boolean;

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ): void {
    const privacyChanged =
      prevProps.isProfilePrivate && !this.props.isProfilePrivate;
    if (!this.privacyChanged && privacyChanged) {
      this.privacyChanged = privacyChanged;
    }

    const groupUpdated =
      prevProps.updateProgress === UpdateProgress.STARTED &&
      this.props.updateProgress === UpdateProgress.STALE;

    if (
      this.privacyChanged &&
      groupUpdated &&
      !isJoined(this.props.group as any) &&
      this.state.shouldChangeMembership
    ) {
      this.handleClick(null);
      this.privacyChanged = false;
    }
  }

  shouldRender() {
    const {
      environment: { isEditor, isMobile, isPreview },
      group,
      activeButton,
    } = this.props;

    if (isEditor) {
      if (isMobile) {
        return false;
      }
      if (isPreview) {
        return false;
      }

      return !isMobile && activeButton === ButtonType.PRIMARY;
    }

    if (!canJoinGroup(group)) {
      return false;
    }

    if (isJoined(group as any)) {
      return false;
    }

    return true;
  }

  render() {
    const { t, group, isProfileUpdating, updateProgress } = this.props;

    if (!this.shouldRender()) {
      return null;
    }

    const { buttonLabelKey, priority } = getMembershipButtonPreferences(group);
    const disabled =
      isProfileUpdating || updateProgress !== UpdateProgress.STALE;
    return (
      <div className={classname(classes.root, this.props.className || '')}>
        <Button
          fullWidth
          priority={priority}
          onClick={this.handleClick}
          data-hook={DATA_HOOKS.membershipButton}
          disabled={disabled}
        >
          {t(buttonLabelKey)}
        </Button>
      </div>
    );
  }

  private readonly handleClick = (e: any) => {
    e && e.stopPropagation();
    if (this.props.isProfilePrivate) {
      this.props.openProfileDialog();
      this.setState({ shouldChangeMembership: true });
      return;
    }
    const { biOrigin, changeMembership } = this.props;
    changeMembership(biOrigin!);
    this.setState({ shouldChangeMembership: false });
  };
}

const enhance = compose(
  withTranslation(),
  WithGroup,
  withProfile,
  withMembershipChangeAction,
  withAppData,
  withEnvironment,
);

export const MembershipButton: React.FunctionComponent<MembershipButtonProps> =
  enhance(MembershipButtonComponent);

MembershipButton.displayName = 'MembershipButton';
