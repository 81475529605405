import React from 'react';
import { WixRicosViewer } from '@wix/ricos-viewer';

import { viewerPluginSelector } from './plugins/viewer-plugins';
import { PRESETS, RichContentViewerProps } from './types';

import './ContentEditor.global.css';
import { classes, st } from './ricos.st.css';
import { useHelpers } from './hooks/useHelpers';

import { useRicosTheme } from './useTheme';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import { AppDataContext } from '../../contexts/AppData/AppData';
import { HostContext } from '../../contexts/AppSettings/HostContext';

export const _RichContentViewer: React.FC<
  Omit<RichContentViewerProps, 'biSettings' | 'instance'>
> = (props) => {
  const { preset, usage, className, handleUserValidationRequest, contentId } =
    props;
  const { experiments } = useExperiments();

  const { instance } = React.useContext(AppDataContext);
  const host = React.useContext(HostContext);
  const { language } = useEnvironment();
  const helpers = useHelpers({
    handleUserValidationRequest,
  });
  const pluginSelector = viewerPluginSelector(preset!, helpers);
  const ricosThemeProps = useRicosTheme(host!);

  return (
    <div
      className={st(classes.root, { mobile: props.isMobile } as any, className)}
    >
      <WixRicosViewer
        {...ricosThemeProps}
        {...props}
        instance={instance}
        plugins={pluginSelector.plugins}
        locale={language}
        linkSettings={{ relValue: 'nofollow ugc', anchorTarget: '_blank' }}
        wixExperiments={experiments}
        biSettings={{
          consumer: 'Groups',
          platform: 'Livesite',
          contentId,
          usage,
        }}
      />
    </div>
  );
};

_RichContentViewer.defaultProps = {
  preset: PRESETS.POST,
  disablePreview: false,
};

export const RichContentViewer = _RichContentViewer;
