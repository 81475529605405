import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import React from 'react';
import { ErrorHandlerContext } from '../../../contexts/ErrorHandler/ErrorHandlerContext';
import { ErrorOrigin } from '../../../controllers/errorHandler/IErrorEvent';
import { mustRegisterToEvent } from '../../../controllers/errorHandler/errorEvent';
import { RegisterEventsDialog } from './RegisterEventsDialog';

interface Props {
  group: ApiTypes.v1.GroupResponse;
}
export const RegisterEvents: React.FC<Props> = ({ group }) => {
  const { errorEvents, errorHandlers } = React.useContext(ErrorHandlerContext);
  if (
    !errorEvents ||
    !mustRegisterToEvent(errorEvents![ErrorOrigin.JoinGroup])
  ) {
    return null;
  }
  const g = new GroupWrapper(group);
  const eventIds = g.getAccessRestriction().getEventIds();
  return (
    <RegisterEventsDialog
      isOpen={true}
      onRequestClose={() =>
        errorHandlers.ignoreError(errorEvents![ErrorOrigin.JoinGroup])
      }
      eventIds={eventIds}
    />
  );
};

RegisterEvents.displayName = 'RegisterEvents';
