import {
  restrictedByAdmin,
  restrictedByEvents,
} from '@wix/social-groups-api/dist/src/model/Group/accessRestriction/v2';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import React from 'react';
import { Private } from '../../Discussion/Private';
import { RestrictedJoin } from '../../Events/Restriction/RestrictedJoin';
import { SomethingWentWrong } from '../../Discussion/SomethingWentWrong';

interface Props {
  accessRestriction: ApiTypes.v1.GroupAccessRestriction;
}

export const GroupAccess: React.FC<Props> = ({ accessRestriction }) => {
  if (restrictedByAdmin(accessRestriction as any)) {
    return <Private />;
  }

  if (restrictedByEvents(accessRestriction as any)) {
    return <RestrictedJoin />;
  }

  return <SomethingWentWrong isOwner={false} />;
};

GroupAccess.displayName = 'GroupAccess';
