import React from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { getSettingsKeyFor } from '../../../../../common/utils/utils';
import { settingsParams } from '../../../Settings/settingsParams';

interface MemberBadgesProps {
  badges: string[];
}

export const MemberBadges: React.FC<MemberBadgesProps> = ({ badges }) => {
  const settings = useSettings();
  const { isMobile } = useEnvironment();

  const showMembersBadgesKey = getSettingsKeyFor('showMembersBadges', isMobile);
  const settingsParamShowMembersBadges = settings.get(settingsParams[showMembersBadgesKey]);

  if (!settingsParamShowMembersBadges) {
    return null;
  }

  if (!badges.length) {
    return null;
  }
  const separator = ' • ';
  return <span>{`${separator}${badges.join(separator)}`}</span>;
};
