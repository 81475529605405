import React from 'react';
import { EventsContext } from '../../../contexts/events/EventsContext';
import { Spinner } from '../../../../../common/components/Spinner';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { Restricted } from '../../Events/Restriction/Restricted';
import { GroupContext } from '../../../contexts/Group/GroupContext';
import { Event } from '@wix/ambassador-wix-events-web/types';
import { filterEvents } from '../../Events/helpers';
import { getEventPage } from '../../Events/getEventPage';
import { RestrictedListModal } from '../../Events/Restriction/RestrictedListModal';

interface Props {
  eventIds: string[];
}

interface Ref {
  _isLoggedIn: boolean;
}

function goToEventPage(eventPage: string | undefined) {
  if (!eventPage) {
    return;
  }
  window.open(eventPage, '_blank'); // TODO: blank?
}

export const EventsRestricted: React.FC<Props> = ({ eventIds }: Props) => {
  const { events, queryIds, error } = React.useContext(EventsContext);

  const { promptLogin, isLoggedIn } = React.useContext(GroupContext);
  const [openModal, setOpenModal] = React.useState(false);
  const aRef = React.useRef({
    _isLoggedIn: isLoggedIn,
  } as Ref);
  const [upcoming, past] = filterEvents(events);
  const { t } = useTranslation();

  React.useEffect(() => {
    queryIds(eventIds);
  }, []);

  React.useEffect(() => {
    const { _isLoggedIn } = aRef.current;
    if (isLoggedIn && !_isLoggedIn) {
      viewEvents(upcoming);
    }
    aRef.current._isLoggedIn = isLoggedIn;
  }, [isLoggedIn]);

  if (!events || error) {
    return <Spinner offset="M" label={t('groups-web.loading')} />;
  }

  function viewEvents(_events: Event[]) {
    if (isLoggedIn) {
      if (_events.length === 1) {
        const eventPage = getEventPage(_events[0].eventPageUrl);
        goToEventPage(eventPage);
      } else {
        setOpenModal(true);
      }
    } else {
      promptLogin();
    }
  }

  function closeModal() {
    setOpenModal(false);
  }

  return (
    <>
      <Restricted upcoming={upcoming} past={past} onViewEvents={viewEvents} />
      {upcoming.length > 1 ? (
        <RestrictedListModal
          events={upcoming}
          isOpen={openModal}
          onRequestClose={closeModal}
        />
      ) : null}
    </>
  );
};

EventsRestricted.displayName = 'EventsRestricted';
