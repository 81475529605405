import { ToolbarType, ViewerPlugin } from 'wix-rich-content-common';

import { PluginTypes, PRESETS, VerticalEmbedProviders } from '../types';

const presets = {
  [PRESETS.POST_EDITOR]: [
    PluginTypes.Image,
    PluginTypes.Gallery,
    PluginTypes.Video,
    PluginTypes.Giphy,
    PluginTypes.FileUpload,
    PluginTypes.Emoji,
    PluginTypes.Poll,
    PluginTypes.Mention,
    PluginTypes.ExternalLink,
    PluginTypes.LinkPreview,
  ],
  [PRESETS.POST]: [
    PluginTypes.Image,
    PluginTypes.Gallery,
    PluginTypes.Video,
    PluginTypes.Giphy,
    PluginTypes.FileUpload,
    PluginTypes.Emoji,
    PluginTypes.Poll,
    PluginTypes.Mention,
    PluginTypes.ExternalLink,
    PluginTypes.LinkPreview,
    PluginTypes.VerticalEmbed,
  ],
  [PRESETS.ABOUT]: [
    PluginTypes.Image,
    PluginTypes.Gallery,
    PluginTypes.Video,
    PluginTypes.Giphy,
    PluginTypes.FileUpload,
    PluginTypes.Emoji,
    PluginTypes.ExternalLink,
  ],
  [PRESETS.CUSTOM_TAB]: [
    // TODO: enable after ricos fix
    // PluginTypes.History,
    PluginTypes.Emoji,
    PluginTypes.Divider,
    PluginTypes.HTML,
    PluginTypes.Giphy,
    PluginTypes.Image,
    PluginTypes.Gallery,
    PluginTypes.Video,
    PluginTypes.FileUpload,
    PluginTypes.SoundCloud,
    PluginTypes.LinkButton,

    // TODO: enable after adding google api key
    // PluginTypes.Map,
    PluginTypes.VerticalEmbed,

    PluginTypes.ExternalLink,
    PluginTypes.Headings,
    PluginTypes.TextColor,
    PluginTypes.TextHighlight,
  ],
};

type PluginMap = {
  [key in PluginTypes]?: (config: any) => ViewerPlugin;
};

export class PluginSelector {
  public plugins: ViewerPlugin[];

  public config = {
    [PluginTypes.Giphy]: {
      insertToolbars: [ToolbarType.SIDE, ToolbarType.FOOTER],
      toolbar: {
        hidden: ['separator1'],
      },
    },
    [PluginTypes.Image]: {
      toolbar: {
        hidden: ['separator1'],
      },
      disableExpand: false,
    },
    [PluginTypes.Poll]: {
      ...this.helpers[PluginTypes.Poll],
    },
    [PluginTypes.Gallery]: {
      accept: 'image/*',
      toolbar: {
        hidden: ['separator2'],
      },
    },
    [PluginTypes.FileUpload]: {
      toolbar: {
        hidden: ['separator', 'separator1', 'separator2', 'separator3'],
      },
      downloadTarget: '_blank',
    },
    [PluginTypes.Video]: {
      toolbar: {
        hidden: ['separator1'],
      },
    },
    [PluginTypes.Mention]: {
      ...this.helpers[PluginTypes.Mention],
    },
    [PluginTypes.LinkPreview]: {
      exposeEmbedButtons: [],
      enableEmbed: false,
      enableLinkPreview: true,
    },
    [PluginTypes.Emoji]: {},
    [PluginTypes.ExternalLink]: {},
    [PluginTypes.VerticalEmbed]: {
      exposeEmbedButtons: [
        VerticalEmbedProviders.EVENT,
        VerticalEmbedProviders.BOOKING,
        VerticalEmbedProviders.PRODUCT,
      ],
    },
  };

  constructor(pluginMap: PluginMap, preset: PRESETS, protected helpers: any) {
    this.plugins = presets[preset]
      .filter((pluginName) => pluginMap[pluginName])
      .map((pluginName) => {
        // @ts-expect-error
        return pluginMap[pluginName](this.config[pluginName]);
      });
  }
}
