import React from 'react';
// WIX-UI-TPA
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { Avatar } from 'wix-ui-tpa/Avatar';
// API
import { Anonymous } from '@wix/social-groups-api/dist/src/model/Member/Member';

import { PluginsIconBar } from './PluginsIconBar';

// STYLES
import { classes, st } from './NewPost.st.css';
import { RawDraftContentState } from '../../../../../common/ContentEditor/types';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { WithGroup, WithGroupProps } from '../../../contexts/Group/WithGroup';

import {
  withSiteMembers,
  WithSiteMembers,
} from '../../../contexts/SiteMembers/withSiteMembers';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../../contexts/TPAComponent/withTpaComponentsConfig';
import { Box } from '../../../../../common/components/Box/Box';
import { compose } from '../../../../../common/utils/compose';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { settingsParams } from '../../../Settings/settingsParams';
import { ContentConverter } from '../../../../../common/ContentEditor/content/ContentConverter';
import { NEW_POST_BOX } from '../dataHooks';
import { Member } from '@wix/ambassador-members-ng-api/types';

export interface NewPostBoxProps {
  draft?: RawDraftContentState<any>;

  onClick(): void;
}

type Props = NewPostBoxProps &
  WithGroupProps &
  WithSiteMembers &
  WithTpaComponentsConfigProps;
const NewPostComponent: React.FC<Props> = (props) => {
  const { onClick, currentSiteMember, mobile, draft, membersUpdate } = props;
  const { t } = useTranslation();

  const settings = useSettings();

  const updatingCurrentMember =
    membersUpdate &&
    currentSiteMember &&
    membersUpdate.includes(currentSiteMember.id!);

  const placeholder = settings.get(settingsParams.newPostBoxLabel);
  return (
    <Box
      onClick={(!updatingCurrentMember || undefined) && onClick}
      onKeyPress={(!updatingCurrentMember || undefined) && onClick}
      data-hook={NEW_POST_BOX}
      className={st(classes.root, {
        mobile,
        hasDraft: !!draft,
        updatingCurrentMember,
      } as any)}
      aria-label={t('groups-web.discussion.create-post.placeholder')}
      tabIndex={0}
      role="button"
      withSideBorders={!mobile}
    >
      <Avatar
        src={currentSiteMember.profile?.photo?.url}
        className={classes.avatar}
      />

      {draft ? (
        <Text
          typography={TYPOGRAPHY.runningText}
          className={classes.placeholder}
        >
          {ContentConverter.parseTextFromRaw(draft)}
        </Text>
      ) : (
        <>
          <Text
            typography={TYPOGRAPHY.runningText}
            className={classes.placeholder}
          >
            {placeholder || t('groups-web.discussion.create-post.placeholder')}
          </Text>
          <PluginsIconBar mobile={mobile} />
        </>
      )}
    </Box>
  );
};

NewPostComponent.defaultProps = {
  currentSiteMember: Anonymous as Member,
};

const enhance = compose(WithGroup, withTpaComponentsConfig, withSiteMembers);

export const NewPost = enhance(
  NewPostComponent,
) as React.ComponentType<NewPostBoxProps>;

NewPost.displayName = 'NewPost';
