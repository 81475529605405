import { Event, EventStatus } from '@wix/ambassador-wix-events-web/types';

import partition from 'lodash/partition';
import sortBy from 'lodash/sortBy';

export function filterEvents(
  events: Event[],
): [upcoming: Event[], past: Event[]] {
  const [upcoming, past] = partition(events, (event) =>
    [EventStatus.STARTED, EventStatus.SCHEDULED].includes(
      event.status as EventStatus,
    ),
  );

  return [upcoming || [], past || []];
}

export function sortUpcoming(events: Event[]): Event[] {
  return sortBy(events, [
    'event',
    'scheduling',
    'config',
    'startDate',
  ]).reverse();
}

export function sortPast(events: Event[]): Event[] {
  return sortBy(events, ['event', 'scheduling', 'config', 'startDate']);
}
