import React from 'react';
import { ModalProps, ModalV2 } from '../../../../../common/components/Modal';
import { EventsRestricted } from './EventsRestricted';
import { st, classes } from './RegisterEventsDialog.st.css';

type Props = ModalProps & { eventIds: string[] };

export const RegisterEventsDialog: React.FC<Props> = ({
  isOpen,
  onRequestClose,
  eventIds,
}) => {
  return (
    <ModalV2
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      withCloseButton={true}
    >
      <ModalV2.Content className={st(classes.root)}>
        <EventsRestricted eventIds={eventIds} />
      </ModalV2.Content>
    </ModalV2>
  );
};

RegisterEventsDialog.displayName = 'RegisterEventsDialog';
